import React, { useRef } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Line } from 'react-chartjs-2';
import { CircularProgress } from '@mui/material';
import { leapYearDates } from './allDates';
import { getSeasonalTimeseriesColor, getSeasonalChartPointsData } from './utils';
import './geoml.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin,
  annotationPlugin
);

const FrackingChart = ({ datesAndLables, isLoading, chartRef, chartType }) => {
  const isMobileScreen = window.innerWidth < 600;
  const dynamicDatasets = Object.keys(datesAndLables).map((year) => ({
    label: year,
    data: getSeasonalChartPointsData(datesAndLables[year], year),
    borderColor: getSeasonalTimeseriesColor(year),
    backgroundColor: `rgba${getSeasonalTimeseriesColor(year).slice(3, -1)},0.5)`,
    pointRadius: 2,
    spanGaps: true,
    hidden:  (+year === 2020 || +year === 2021) && (chartType === "Fracking" || chartType === "Rigs"),
  }));

  const data = {
    labels: leapYearDates,
    datasets: dynamicDatasets,
  };

  ChartJS.register(
    {
      id: 'uniqueid5', //typescript crashes without id
      afterDraw: function (chart, easing) {
        if (chart.tooltip._active && chart.tooltip._active.length) {
          const activePoint = chart.tooltip._active[0];
          const ctx = chart.ctx;
          const x = activePoint.element.x;
          const topY = chart.scales.y.top;
          const bottomY = chart.scales.y.bottom;
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 2;
          ctx.strokeStyle = '#3C3D6B';
          ctx.stroke();
          ctx.restore();
        }
      }
    }
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: true,
    animationSteps: 1,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'index',
      intersect: false
    },
    plugins: {
      pan: {
        enabled: true,
        mode: 'x',
      },
      // zoom: {
      //   zoom: {
      //     wheel: {
      //       enabled: true,
      //     },
      //     pinch: {
      //       enabled: true,
      //     },
      //     mode: 'xy',
      //   },
      // },
      tooltip: {
        mode: 'index',
        intersect: false,
      },

      legend: {
        position: 'top',
        align: 'center',
        labels: {
          boxWidth: isMobileScreen ? 30 : 40,
          boxHeight: isMobileScreen ? 15 : 20,
          font: {
            size: isMobileScreen ? 11 : 14
          },

        }
      },
      title: {
        display: true,
        text: `${chartType}`,
        font: {
          size: isMobileScreen ? 14 : 18
        }
      },
    },
    scales: {
      x: {
        ticks: {
          maxTicksLimit: 12,
          font: {
            size: isMobileScreen ? 11 : 16,
            weight: isMobileScreen ? '' : 'bold'
          },
          callback: function (value, index) {
            return this.getLabelForValue(index).substr(3);
          }
        },
      },
      y: {
        ticks: {
          precision: 0,
          font: {
            size: isMobileScreen ? 11 : 16,
            weight: isMobileScreen ? '' : 'bold'
          }
        },
        beginAtZero: true,
        display: true,
        title: {
          display: true,
          text: `No of ${chartType} ${chartType === 'Fracking' ? 'Crews' : ''}`,
          color: '#333',
          font: {
            // family: 'Times',
            size: isMobileScreen ? 14 : 20,
            style: isMobileScreen ? '' : 'normal',
            lineHeight: 1.2,
          },
          padding: { top: 30, left: 0, right: 20, bottom: 0 },
        },
      },
    },
    elements: {
      point: {
        radius: window.innerWidth < 600 ? 0 : 4,
      },
    },
  };

  return (
    <div className="frackingData">
      {isLoading ? (
        <div className="loadingBox">
          <CircularProgress size={80} />
        </div>
      ) : (
        <Line ref={chartRef} options={options} data={data} />
      )}
    </div>
  );
};

export default FrackingChart;
