import { useEffect } from 'react'
import { useLeaflet } from 'react-leaflet';
import L from 'leaflet'

import vectorGrid from 'leaflet.vectorgrid'
const MikePipelines = ({ zoomLevel }) => {
  const { map } = useLeaflet()
  const container = map



 
  // Using Hook below was causing re-renders of tiles on every zoom
  useEffect(() => {

    var pipelinesUrl = 'https://hyperiontileserver.com/api/rpc/public.function_source/{z}/{x}/{y}.pbf?min_diameter=30&max_diameter=50&commodity_type={NG,NGT,NFG,NGZ}',
    gatheringPipelinesUrl = 'https://hyperiontileserver.com/api/rpc/public.function_source/{z}/{x}/{y}.pbf?min_diameter=0&max_diameter=30&commodity_type={NG,NGT,NFG,NGG,NGZ}'

  // MVT layer options
  const mapboxVectorTileOptions = {
    interactive: true
  };

  const pipelines = L.vectorGrid.protobuf(pipelinesUrl, mapboxVectorTileOptions).on('click', function (e) {
    // The .on method attaches an event handler
    L.popup()
      .setContent(e.layer.properties.OPER_NM)
      .setLatLng(e.latlng)
      .openOn(map)
  });

  const gatheringPipelines = L.vectorGrid.protobuf(gatheringPipelinesUrl, mapboxVectorTileOptions).on('click', function (e) {
    // The .on method attaches an event handler
    L.popup()
      .setContent(e.layer.properties.OPER_NM)
      .setLatLng(e.latlng)
      .openOn(map)
  });

  if ((!map.hasLayer(pipelines))) {
    // container.addLayer(osm)
    container.addLayer(pipelines)
  }

  if (!map.hasLayer(gatheringPipelines) && map.getZoom() > 9) {
    container.addLayer(gatheringPipelines)
  }

  map.on('zoomend', function (e) {
    if (map.hasLayer(gatheringPipelines) && map.getZoom() < 10) {
      container.removeLayer(gatheringPipelines)
    }
  })


    return () => {
      container.removeLayer(pipelines)
    }
  }, [])

  return null
}

export default MikePipelines