import moment from 'moment';

const allDates = [
  '01 Jan',
  '02 Jan',
  '03 Jan',
  '04 Jan',
  '05 Jan',
  '06 Jan',
  '07 Jan',
  '08 Jan',
  '09 Jan',
  '10 Jan',
  '11 Jan',
  '12 Jan',
  '13 Jan',
  '14 Jan',
  '15 Jan',
  '16 Jan',
  '17 Jan',
  '18 Jan',
  '19 Jan',
  '20 Jan',
  '21 Jan',
  '22 Jan',
  '23 Jan',
  '24 Jan',
  '25 Jan',
  '26 Jan',
  '27 Jan',
  '28 Jan',
  '29 Jan',
  '30 Jan',
  '31 Jan',
  '01 Feb',
  '02 Feb',
  '03 Feb',
  '04 Feb',
  '05 Feb',
  '06 Feb',
  '07 Feb',
  '08 Feb',
  '09 Feb',
  '10 Feb',
  '11 Feb',
  '12 Feb',
  '13 Feb',
  '14 Feb',
  '15 Feb',
  '16 Feb',
  '17 Feb',
  '18 Feb',
  '19 Feb',
  '20 Feb',
  '21 Feb',
  '22 Feb',
  '23 Feb',
  '24 Feb',
  '25 Feb',
  '26 Feb',
  '27 Feb',
  '28 Feb',
  '01 Mar',
  '02 Mar',
  '03 Mar',
  '04 Mar',
  '05 Mar',
  '06 Mar',
  '07 Mar',
  '08 Mar',
  '09 Mar',
  '10 Mar',
  '11 Mar',
  '12 Mar',
  '13 Mar',
  '14 Mar',
  '15 Mar',
  '16 Mar',
  '17 Mar',
  '18 Mar',
  '19 Mar',
  '20 Mar',
  '21 Mar',
  '22 Mar',
  '23 Mar',
  '24 Mar',
  '25 Mar',
  '26 Mar',
  '27 Mar',
  '28 Mar',
  '29 Mar',
  '30 Mar',
  '31 Mar',
  '01 Apr',
  '02 Apr',
  '03 Apr',
  '04 Apr',
  '05 Apr',
  '06 Apr',
  '07 Apr',
  '08 Apr',
  '09 Apr',
  '10 Apr',
  '11 Apr',
  '12 Apr',
  '13 Apr',
  '14 Apr',
  '15 Apr',
  '16 Apr',
  '17 Apr',
  '18 Apr',
  '19 Apr',
  '20 Apr',
  '21 Apr',
  '22 Apr',
  '23 Apr',
  '24 Apr',
  '25 Apr',
  '26 Apr',
  '27 Apr',
  '28 Apr',
  '29 Apr',
  '30 Apr',
  '01 May',
  '02 May',
  '03 May',
  '04 May',
  '05 May',
  '06 May',
  '07 May',
  '08 May',
  '09 May',
  '10 May',
  '11 May',
  '12 May',
  '13 May',
  '14 May',
  '15 May',
  '16 May',
  '17 May',
  '18 May',
  '19 May',
  '20 May',
  '21 May',
  '22 May',
  '23 May',
  '24 May',
  '25 May',
  '26 May',
  '27 May',
  '28 May',
  '29 May',
  '30 May',
  '31 May',
  '01 Jun',
  '02 Jun',
  '03 Jun',
  '04 Jun',
  '05 Jun',
  '06 Jun',
  '07 Jun',
  '08 Jun',
  '09 Jun',
  '10 Jun',
  '11 Jun',
  '12 Jun',
  '13 Jun',
  '14 Jun',
  '15 Jun',
  '16 Jun',
  '17 Jun',
  '18 Jun',
  '19 Jun',
  '20 Jun',
  '21 Jun',
  '22 Jun',
  '23 Jun',
  '24 Jun',
  '25 Jun',
  '26 Jun',
  '27 Jun',
  '28 Jun',
  '29 Jun',
  '30 Jun',
  '01 Jul',
  '02 Jul',
  '03 Jul',
  '04 Jul',
  '05 Jul',
  '06 Jul',
  '07 Jul',
  '08 Jul',
  '09 Jul',
  '10 Jul',
  '11 Jul',
  '12 Jul',
  '13 Jul',
  '14 Jul',
  '15 Jul',
  '16 Jul',
  '17 Jul',
  '18 Jul',
  '19 Jul',
  '20 Jul',
  '21 Jul',
  '22 Jul',
  '23 Jul',
  '24 Jul',
  '25 Jul',
  '26 Jul',
  '27 Jul',
  '28 Jul',
  '29 Jul',
  '30 Jul',
  '31 Jul',
  '01 Aug',
  '02 Aug',
  '03 Aug',
  '04 Aug',
  '05 Aug',
  '06 Aug',
  '07 Aug',
  '08 Aug',
  '09 Aug',
  '10 Aug',
  '11 Aug',
  '12 Aug',
  '13 Aug',
  '14 Aug',
  '15 Aug',
  '16 Aug',
  '17 Aug',
  '18 Aug',
  '19 Aug',
  '20 Aug',
  '21 Aug',
  '22 Aug',
  '23 Aug',
  '24 Aug',
  '25 Aug',
  '26 Aug',
  '27 Aug',
  '28 Aug',
  '29 Aug',
  '30 Aug',
  '31 Aug',
  '01 Sep',
  '02 Sep',
  '03 Sep',
  '04 Sep',
  '05 Sep',
  '06 Sep',
  '07 Sep',
  '08 Sep',
  '09 Sep',
  '10 Sep',
  '11 Sep',
  '12 Sep',
  '13 Sep',
  '14 Sep',
  '15 Sep',
  '16 Sep',
  '17 Sep',
  '18 Sep',
  '19 Sep',
  '20 Sep',
  '21 Sep',
  '22 Sep',
  '23 Sep',
  '24 Sep',
  '25 Sep',
  '26 Sep',
  '27 Sep',
  '28 Sep',
  '29 Sep',
  '30 Sep',
  '01 Oct',
  '02 Oct',
  '03 Oct',
  '04 Oct',
  '05 Oct',
  '06 Oct',
  '07 Oct',
  '08 Oct',
  '09 Oct',
  '10 Oct',
  '11 Oct',
  '12 Oct',
  '13 Oct',
  '14 Oct',
  '15 Oct',
  '16 Oct',
  '17 Oct',
  '18 Oct',
  '19 Oct',
  '20 Oct',
  '21 Oct',
  '22 Oct',
  '23 Oct',
  '24 Oct',
  '25 Oct',
  '26 Oct',
  '27 Oct',
  '28 Oct',
  '29 Oct',
  '30 Oct',
  '31 Oct',
  '01 Nov',
  '02 Nov',
  '03 Nov',
  '04 Nov',
  '05 Nov',
  '06 Nov',
  '07 Nov',
  '08 Nov',
  '09 Nov',
  '10 Nov',
  '11 Nov',
  '12 Nov',
  '13 Nov',
  '14 Nov',
  '15 Nov',
  '16 Nov',
  '17 Nov',
  '18 Nov',
  '19 Nov',
  '20 Nov',
  '21 Nov',
  '22 Nov',
  '23 Nov',
  '24 Nov',
  '25 Nov',
  '26 Nov',
  '27 Nov',
  '28 Nov',
  '29 Nov',
  '30 Nov',
  '01 Dec',
  '02 Dec',
  '03 Dec',
  '04 Dec',
  '05 Dec',
  '06 Dec',
  '07 Dec',
  '08 Dec',
  '09 Dec',
  '10 Dec',
  '11 Dec',
  '12 Dec',
  '13 Dec',
  '14 Dec',
  '15 Dec',
  '16 Dec',
  '17 Dec',
  '18 Dec',
  '19 Dec',
  '20 Dec',
  '21 Dec',
  '22 Dec',
  '23 Dec',
  '24 Dec',
  '25 Dec',
  '26 Dec',
  '27 Dec',
  '28 Dec',
  '29 Dec',
  '30 Dec',
  '31 Dec',
];

const leapYearDates = [...allDates]
leapYearDates.splice(59,0, "29 Feb")

const leapYear = (year) => {
  return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
};

const fillEmptyDatesWithAstrick = (
  availableDates,
  availableLabels,
  year,
  northEastVisible,
  isDuc
) => {
  const newDates = [];
  var startdate = moment();
  startdate = startdate.subtract(10, 'days');
  startdate = startdate.format('DD MMM YYYY');

  let arrToUse = allDates;

  if(isDuc) {
    arrToUse = leapYearDates
  }

  arrToUse.forEach((date) => {
    const indexOf = availableLabels.indexOf(date);
    if (indexOf === -1) {
      if ((year >= 2023) &&  moment(`${date} ${year}`).isAfter(startdate)) {
        console.log(moment(`${date} ${year}`)._i, 'is after', startdate)
        newDates.push('*');
      } else {
        if (
          northEastVisible &&
          year === 2021 &&
          moment(`${date} ${year}`).isBefore(`31 Mar ${year}`)
        ) {
          // This "if" check is the problem in firefox
          newDates.push('*');
        } else {
          if (date === '29 Feb') {
            newDates.push('*');
          } else {
            newDates.push(0);
          }
        }
      }
    } else {
      newDates.push(availableDates[indexOf]);
    }
  });
  return newDates;
};


export {leapYearDates, allDates, fillEmptyDatesWithAstrick};