import React from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import './footer.scss'

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__content">
        
      <div className="footer__left">
      <a href="mailto:info@synmax.com" className="footer__item footer__item--1">
        <div className="footer__icon">
          {' '}
          <MailOutlineIcon
            style={{
              color: '#F5AA57',
              fontSize: '22px',
              marginBottom: 2,
            }}
          />
        </div>
        <div className="footer__text">info@synmax.com</div>
      </a>
        <div className="footer__icon">
          {' '}
          <LocalPhoneIcon
            style={{
              color: '#F5AA57',
              fontSize: '22px',
              marginBottom: 2,
            }}
          />
        </div>
        <a href="tel:+18339SYNMAX" style={{color:"#424c62", marginRight:"5px"}}> +1 833-9SYNMAX </a>
                /  
        <a href="tel:+18339796629" style={{color:"#424c62", marginLeft:"5px"}}> +1 (833) 979-6629 </a>
      </div>
     
     <div className="footer__right">
     <a href="tel:%2015555555555" className="footer__item">
        <div className="footer__icon">
          {' '}
          <LocationOnIcon
            style={{
              color: '#F5AA57',
              fontSize: '22px',
              marginBottom: 2,
            }}
          />
        </div>
        <div className="footer__text">777 N Eldridge Pkwy, STE 890, Houston, TX 77079</div>
      </a>
     </div>
      </div>
    </div>
  );
}

export default Footer