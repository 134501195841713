import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import {
  Map,
  TileLayer,
  LayersControl,
  GeoJSON,
  ScaleControl,
  Marker,
  Popup,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import MouseCoordinates from '../../components/MouseCoordinates';

const moveMap = (selectedRegion, setCenter) => {
  if (selectedRegion === 'Gulf') {
    setCenter({
      lat: 31.33,
      lng: -98.34,
    });
  }
  if (selectedRegion === 'West') {
    setCenter({
      lat: 39.08,
      lng: -106.58,
    });
  }
  if (selectedRegion === 'NorthEast') {
    setCenter({
      lat: 40.43,
      lng: -80.5,
    });
  }
  if (selectedRegion === 'MidWest') {
    setCenter({
      lat: 41.56,
      lng: -99.05,
    });
  }
}

const Leaflet = ({ selectedRegion, selectedSubRegions, regionsAndSubregions }) => {
  const {regions, subRegions} = regionsAndSubregions;
  const [selectedRegionKey, setSelectedRegionKey] = useState(0);
  const [highlightKey, setHighlightKey] = useState(0);
  const [subRegionsData, selectedSubRegionsData] = useState(null);
  const [highlightedSubRegion, setHighlightedSelectedSubRegion] =
    useState(null);
  const [allRegions, setAllRegions] = useState(null);
  const [center, setCenter] = useState({
    lat: 39.8283,
    lng: -96,
  });
  const [zoomLevel, setZoomLevel] = useState(3);

  useEffect(() => {
    const highlightedSubRegions = subRegions.features.filter(
      (region) => selectedSubRegions.includes(region.properties.sub_region)
    );

    const latestSubRegion = subRegions.features.filter(
      (region) => (region.properties.sub_region) === selectedSubRegions[selectedSubRegions.length - 1]
    );
    console.log(latestSubRegion, 'latestSubRegion')
    latestSubRegion.length > 0 && moveMap(latestSubRegion[0].properties.skylar_reg, setCenter)
    setHighlightedSelectedSubRegion(highlightedSubRegions);
    setHighlightKey(highlightKey + 1);
  }, [selectedSubRegions]);

  useEffect(() => {
    const allRegions = regions.features.filter(
      (region) => region.properties.skylar_reg !== selectedRegion
    );
    setAllRegions(allRegions);
    const lastElement = selectedRegion.length !== 0 ? selectedRegion[selectedRegion.length - 1] : '';
  
    moveMap(lastElement, setCenter)
    if (lastElement !== '' && zoomLevel !== 4) {
      setZoomLevel(4);
    }
    if (lastElement === '' && zoomLevel !== 3) {
      setZoomLevel(3);
      setCenter({
        lat: 39.8283,
        lng: -96,
      })
    }
    console.log(selectedRegion, zoomLevel)
    setSelectedRegionKey(selectedRegionKey + 1);

    const subRegionData = subRegions.features.filter(
      (sub) => selectedRegion.includes(sub.properties.skylar_reg)
    );
    selectedSubRegionsData(subRegionData);
    console.log(selectedRegion, 'selectedRegion')
  }, [selectedRegion]);

  const onEachFeature = (feature, layer) => {
    const { skylar_reg } = feature.properties;
    if (selectedRegion !== skylar_reg) {
      layer.bindPopup(`${skylar_reg}`);

      layer.on({
        mouseover: () => {
          layer.openPopup();
        },
        mouseout: (e) => {
          layer.closePopup();
        },
      });
    }
  };

  const onEachSubRegionFeature = (feature, layer) => {
    const { sub_region } = feature.properties;
    layer.bindPopup(`${sub_region}`);

    layer.on({
      mouseover: () => {
        layer.openPopup();
      },
      mouseout: (e) => {
        layer.closePopup();
      },
    });
  };

  const styleRegions = (feature, type) => {
    const { skylar_reg } = feature.properties;
    let color = '#F5AA57';

    if (skylar_reg === 'Gulf') {
      color = '#F5AA57';
    }
    if (skylar_reg === 'West') {
      color = '#24BEBB';
    }
    if (skylar_reg === 'NorthEast') {
      color = '#24A0CA';
    }
    if (skylar_reg === 'MidWest') {
      color = "#F85348";
    }
    const returnObject =  { color };

    if(type === 'subRegion') {
      returnObject.fillOpacity = 1
    }
    if(type === 'regions') {
      returnObject.weight = 0
      returnObject.fillOpacity = 0.5
    }
    return returnObject
  };

  const subRegionsDataGeojson = {
    type: 'FeatureCollection',
    name: 'regions_selected_states',
    crs: {
      type: 'name',
      properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' },
    },
    features: subRegionsData,
  };

  const regionsDataGeojson = {
    type: 'FeatureCollection',
    name: 'regions_selected_states',
    crs: {
      type: 'name',
      properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' },
    },
    features: allRegions,
  };

  const subRegionDataGeojson = {
    type: 'FeatureCollection',
    name: 'regions_selected_states',
    crs: {
      type: 'name',
      properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' },
    },
    features: highlightedSubRegion,
  };
  return (
    <div className="leafletSmall">
      <Map
        key={'reportmap'}
        preferCanvas={true}
        style={{ width: '100%', height: '100%' }}
        className="ukraine__mapComponent"
        center={center}
        zoom={zoomLevel}
        maxZoom={18}
        minZoom={2}
        worldCopyJump={true}
      >
        {allRegions && (
          <GeoJSON
            data={regionsDataGeojson}
            key={selectedRegionKey + 'regions'}
            onEachFeature={onEachFeature}
            style={(e) => styleRegions(e, 'regions')}
          />
        )}

        {highlightedSubRegion && (
          <GeoJSON
            data={subRegionDataGeojson}
            // style={{
            //   color: '#24A0CA',
            //   weight: 1,
            //   opacity: 0.1,
            //   fillOpacity: 1,
            // }}
            key={highlightKey}
            onEachFeature={onEachSubRegionFeature}
            style={(e) => styleRegions(e, 'subRegion')}
          />
        )}

        {subRegionsData && (
          <GeoJSON
            data={subRegionsDataGeojson}
            // style={{
            //   color: '#24A0CA',
            //   weight: 1,
            //   opacity: 1,
            //   fillOpacity: 0.4
            // }}
            style={styleRegions}
            key={selectedRegionKey + 'subregions'}
            onEachFeature={onEachSubRegionFeature}
          />
        )}

        <div className="leaflet-top leaflet-left coordinates">
          <MouseCoordinates />
        </div>
        <LayersControl position="topright">
          <LayersControl.BaseLayer checked name="Map (light)">
            <TileLayer
              preferCanvas={true}
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Map (English)">
            <TileLayer
              preferCanvas={true}
              url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
            />
          </LayersControl.BaseLayer>

          <LayersControl.BaseLayer name="Satellite">
            <TileLayer
              //  noWrap
              url="https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v9/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1Ijoia3Blc3NldHRvIiwiYSI6ImNsODM1aGl5MDAybWQzdW81azkwZWt4c2IifQ.hm20TDIv8E8Vt9PJv1tEyg"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
          </LayersControl.BaseLayer>
        </LayersControl>
      </Map>
    </div>
  );
};

export default Leaflet;
