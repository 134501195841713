import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import GetAppIcon from "@mui/icons-material/GetApp";
import XLSX from "xlsx";
import {
  Button,
  Dialog,
  Snackbar,
  SnackbarContent,
  CircularProgress,
  Box,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import FrackingChart from "./FrackingChart";
import Footer from "../../components/footer/Footer";
import Map from "./Leaflet";
import MapSmall from "./SmallLeafletMap";
import "./geoml.scss";
import BarChart from "./BarChart";
import {
  useStyles,
  getXLSData,
  columnSchema,
  getMonthlyDataforXLS,
  getFlagDataForSubRegion,
  getLineChartDataPointsSeasonal,
  getBarDataSeasonal,
} from "./utils";

const Hyperion = ({ token, logout, isloggedIn, userData }) => {
  const startYear = 2021;
  const ducsStartYear = 2016;
  const currentYear = new Date().getFullYear();

  const years = Array.from(
    { length: currentYear - startYear + 1 },
    (_, i) => startYear + i
  );
  const ducsYears = Array.from(
    { length: currentYear - ducsStartYear + 1 },
    (_, i) => ducsStartYear + i
  );

  const [initialData, setInitialData] = useState([]);
  const [datesAndLabels, setDatesAndLabels] = useState({});
  const [rigsDatesAndLabels, setRigsDatesAndLabel] = useState({});
  const [ducsDatesAndLabels, setDucsDatesAndLabel] = useState({});
  const [barData, setBarData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [clickedRegion, setClickedRegion] = useState({
    Gulf: false,
    Midwest: false,
    Northeast: false,
    West: false,
  });
  const [showDialog, setShowDialog] = useState(false);
  const [userHasAccess, setUserHasAccess] = useState(false);
  const [operators, setOperators] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState("");
  const [subRegions, setSubRegions] = useState([]);
  const [selectedSubRegions, setselectedSubRegions] = useState([]);
  const [counties, setCounties] = useState([]);
  const [selectedCounty, setSelectedCounty] = useState("");
  const [isMapClicked, setIsMapClicked] = useState(false);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [viewChart, setViewChart] = useState(true);
  const [resultMap, setResultMap] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [regionsAndSubregions, setRegionsAndSubregions] = useState(null);
  const chartRef = useRef(null);
  const rigRef = useRef(null);
  const ducRef = useRef(null);
  const styles = useStyles();
  useEffect(() => {
    setSelectedOperator(null);
    setSelectedCounty(null);

    if (localStorage.getItem("showSnack")) {
      setIsSnackBarOpen(true);
      localStorage.removeItem("showSnack");
    }
  }, []);

  useEffect(() => {
    getServerRegionsData();
  }, []);

  const getServerRegionsData = async () => {
    if (!regionsAndSubregions) {
      const regions = await axios.get("/geoml/regions", {
        headers: { Authorization: "Bearer " + token },
      });
      setRegionsAndSubregions(regions.data);
    }
  };

  useEffect(() => {
    userHasAccess && getInitialData();
  }, [userHasAccess]);

  useEffect(() => {
    onFilterChange();
  }, [clickedRegion, selectedOperator, selectedSubRegions, selectedCounty]);

  useEffect(() => {
    const userHasAccess =
      userData &&
      (userData.role === "super_user" ||
        userData.role === "admin" ||
        userData.role === "co-worker" ||
        userData.role === "hyperion_customer" ||
        userData.role === "vulcan_and_hyperion_customer" ||
        (userData.role === "temporary_hyperion_user" &&
          userData.expiry_date.seconds > Date.now() / 1000));
    setUserHasAccess(userHasAccess);
  }, [userData]);

  useEffect(() => {
    const { Gulf, Midwest, Northeast, West } = clickedRegion;
    const isMapClicked = Gulf || Midwest || Northeast || West;
    setIsMapClicked(isMapClicked);
  }, [clickedRegion]);

  const getChartData = async () => {
    const { Gulf, Midwest, Northeast, West } = clickedRegion;
    setIsLoading(true);
    const liveData = await axios.get("/geoml/data", {
      headers: { Authorization: "Bearer " + token },
      params: {
        selectedOperator,
        selectedSubRegion: selectedSubRegions,
        selectedCounty,
        Gulf,
        Midwest,
        Northeast,
        West,
        token,
      },
    });
    setResultMap(liveData.data.resultMap.recordset);
    const allData = liveData.data;
    const resultCount = allData.resultCount.recordset.reverse();
    const drillCount = allData.drillCount.recordset.reverse();
    const ducCount = allData.ducCount.recordset.reverse();
    const monthlyCount = allData.monthlyCount;
    let operators = [];
    allData.distinctOperators.recordset.forEach(
      ({ operator_name }) => operator_name && operators.push(operator_name)
    );
    setOperators(operators);

    if (
      resultCount.length === 0 &&
      drillCount.length === 0 &&
      ducCount.length === 0 &&
      monthlyCount.recordset.length === 0
    ) {
      setIsSnackBarOpen(true);
    }

    setIsLoading(false);

    const frackingData = getLineChartDataPointsSeasonal(resultCount, false);
    setDatesAndLabels(() => ({ ...frackingData }));

    const rigsData = getLineChartDataPointsSeasonal(drillCount, false);
    setRigsDatesAndLabel(() => ({ ...rigsData }));

    const ducsData = getLineChartDataPointsSeasonal(ducCount, true);
    setDucsDatesAndLabel(() => ({ ...ducsData }));
    const monthlyData = getBarDataSeasonal(monthlyCount.recordset.reverse());
    setBarData(monthlyData);
  };

  const onFilterChange = () => {
    // This is for chart visuals using liveData
    !isLoading && getChartData();

    // The below code is to load appropriate filters
    const { Gulf, Midwest, Northeast, West } = clickedRegion;
    const arrOfConditions = [];
    if (Gulf || Midwest || Northeast || West) {
      Gulf && arrOfConditions.push("gulf");
      Midwest && arrOfConditions.push("midwest");
      Northeast && arrOfConditions.push("northeast");
      West && arrOfConditions.push("west");
      let filteredData = initialData.filter((item) =>
        arrOfConditions.includes(item.skylar_region)
      );
      let subRegions = [];
      filteredData.forEach((item) => subRegions.push(item.sub_region));
      subRegions = removeDuplicatesAndSortFromArr(subRegions);
      let counties = [];
      filteredData.forEach((item) => counties.push(item.county));
      counties = removeDuplicatesAndSortFromArr(counties);
      if (selectedSubRegions && selectedSubRegions.length !== 0) {
        filteredData = filteredData.filter((item) =>
          selectedSubRegions.includes(item.sub_region)
        );
        let newCounties = [];
        filteredData.forEach((item) => newCounties.push(item.county));
        newCounties = removeDuplicatesAndSortFromArr(newCounties);
        counties = newCounties;
      }

      if (selectedCounty && selectedCounty !== "") {
        filteredData = filteredData.filter(
          (item) => item.county === selectedCounty
        );
      }
      setCounties(counties);
      setSubRegions(subRegions);
    }
  };

  const removeDuplicatesAndSortFromArr = (a) => {
    var seen = {};
    return a
      .filter(function (item) {
        return seen.hasOwnProperty(item) ? false : (seen[item] = true);
      })
      .sort(function (a, b) {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });
  };

  const getInitialData = async () => {
    setIsLoading(true);
    const frackingData = await axios.get("/geoml", {
      headers: { Authorization: "Bearer " + token },
    });
    // The .sort function sorts the objects from latest to oldest object
    let dataArr = frackingData.data.data.sort(function (a, b) {
      return new Date(b.acquired) - new Date(a.acquired);
    });
    setInitialData(dataArr.reverse());
    setIsLoading(false);
  };

  const downloadXLS = () => {
    const wb = XLSX.utils.book_new();
    const generateYearSheets = (year) => {
      const frackWs = XLSX.utils.json_to_sheet(
        getXLSData(datesAndLabels[year], false)
      );
      XLSX.utils.book_append_sheet(wb, frackWs, `Frack ${year}`);

      const rigsWs = XLSX.utils.json_to_sheet(
        getXLSData(rigsDatesAndLabels[year], false)
      );
      XLSX.utils.book_append_sheet(wb, rigsWs, `Rigs ${year}`);

      const monthlyWs = XLSX.utils.json_to_sheet(
        getMonthlyDataforXLS(barData[year], false)
      );
      XLSX.utils.book_append_sheet(wb, monthlyWs, `Monthly ${year}`);
    };

    const generateDucSheets = (year) => {
      const ducsWs = XLSX.utils.json_to_sheet(
        getXLSData(ducsDatesAndLabels[year], false)
      );
      XLSX.utils.book_append_sheet(wb, ducsWs, `Ducs ${year}`);
    };

    years.forEach((year) => {
      generateYearSheets(year);
    });

    ducsYears.forEach((year) => {
      generateDucSheets(year);
    });

    XLSX.writeFile(wb, `hyperion.xlsx`);
    setShowDialog(false);
  };
  return (
    <div className="geoml admin">
      <Snackbar
        open={isSnackBarOpen}
        autoHideDuration={4000}
        onClose={() => setIsSnackBarOpen(false)}
        message="No Data for those filters"
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <SnackbarContent
          message={
            userHasAccess ? (
              "No Data for those filters"
            ) : (
              <div>
                Account Created!{" "}
                <a href="mailto:info@synmax.com" style={{ color: "#fff" }}>
                  Contact Us
                </a>{" "}
                to get full access.
              </div>
            )
          }
        />
      </Snackbar>

      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        classes={{ root: "MuiDialog-root downloadCsv--geoml" }}
      >
        <div className="downloadCsv__dialog">
          <div className="downloadCsv__table">
            <div className="downloadCsv__tables">
              {years.map((year) => (
                <div key={year} className="singleTable">
                  <div className="singleTable__heading">Frack({year})</div>
                  <DataGrid
                    rows={getXLSData(datesAndLabels[year], true)}
                    columns={columnSchema}
                    pageSize={4}
                    rowsPerPageOptions={[8]}
                  />
                </div>
              ))}
              {years.map((year) => (
                <div key={year} className="singleTable">
                  <div className="singleTable__heading">Rigs({year})</div>
                  <DataGrid
                    rows={getXLSData(rigsDatesAndLabels[year], true)}
                    columns={columnSchema}
                    pageSize={4}
                    rowsPerPageOptions={[8]}
                  />
                </div>
              ))}
              {years.map((year) => (
                <div key={year} className="singleTable">
                  <div className="singleTable__heading">Monthly({year})</div>
                  <DataGrid
                    rows={getMonthlyDataforXLS(barData[year], true)}
                    columns={columnSchema}
                    pageSize={4}
                    rowsPerPageOptions={[8]}
                  />
                </div>
              ))}
              {ducsYears.map((year) => (
                <div className="singleTable" key={year}>
                  <div className="singleTable__heading">DUCs({year})</div>
                  <DataGrid
                    rows={getXLSData(ducsDatesAndLabels[year], true)}
                    columns={columnSchema}
                    pageSize={4}
                    rowsPerPageOptions={[8]}
                  />
                </div>
              ))}
            </div>

            <div id="download">
              <Button variant="outlined" onClick={downloadXLS}>
                Download XLS
              </Button>
            </div>
          </div>
          <br />
        </div>
      </Dialog>

      <div className="geoml__navbar">
        <div className="geoml__navbar--logo">
          <img src="/new_logo.png" alt="Synmax" />
        </div>
        <div className="geoml__navbar--title">Hyperion</div>
        <div className="geoml__navbar--auth">
          {isloggedIn ? (
            <Button
              color="error"
              onClick={logout}
              style={{
                color: "#fff",
                backgroundColor: "#F85348",
                border: "1px solid #F85348",
              }}
            >
              Logout
            </Button>
          ) : (
            <Link
              style={{ textDecoration: "none", color: "#24A0CA" }}
              to="/login"
            >
              Login
            </Link>
          )}
        </div>
      </div>
      {userHasAccess && (
        <div className="geoml__content">
          {regionsAndSubregions ? (
            <>
              <div className="geoml__top">
                {viewChart && regionsAndSubregions && (
                  <MapSmall
                    selectedRegion={selectedRegion}
                    selectedSubRegions={selectedSubRegions}
                    regionsAndSubregions={regionsAndSubregions}
                  />
                )}

                {userHasAccess && (
                  <div className="geoml__top--2">
                    {viewChart && (
                      <>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          multiple
                          options={["Gulf", "West", "NorthEast", "MidWest"]}
                          sx={{ width: 250, marginTop: 3 }}
                          renderInput={(params) => (
                            <TextField {...params} label="Region" />
                          )}
                          value={selectedRegion}
                          classes={{
                            option: styles.option,
                          }}
                          onChange={(event, newValue) => {
                            setSelectedRegion(newValue);
                            setClickedRegion({
                              Gulf: newValue.includes("Gulf"),
                              Midwest: newValue.includes("MidWest"),
                              Northeast: newValue.includes("NorthEast"),
                              West: newValue.includes("West"),
                            });
                            setselectedSubRegions([]);
                            setSelectedCounty("");
                          }}
                        />

                        <Tooltip
                          title={
                            isMapClicked ? (
                              ""
                            ) : (
                              <span
                                style={{ fontSize: 16, letterSpacing: "0.5px" }}
                              >
                                Select a region above to select sub regions and
                                counties
                              </span>
                            )
                          }
                        >
                          <div style={{ opacity: isMapClicked ? 1 : 0.3 }}>
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={subRegions}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                  {...props}
                                >
                                  <img
                                    loading="lazy"
                                    width="20"
                                    src={`/states/${
                                      option
                                        ? getFlagDataForSubRegion(option)
                                        : ""
                                    }.png`}
                                    alt=""
                                  />
                                  {option}
                                </Box>
                              )}
                              multiple
                              sx={{ width: 250, marginTop: 1 }}
                              renderInput={(params) => (
                                <TextField {...params} label="Sub Regions" />
                              )}
                              value={selectedSubRegions}
                              onChange={(event, newValue) => {
                                setselectedSubRegions(newValue);
                                setSelectedCounty("");
                              }}
                              disabled={!isMapClicked}
                            />
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={counties}
                              sx={{ width: 250, marginTop: 1 }}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                  {...props}
                                >
                                  <img
                                    loading="lazy"
                                    width="20"
                                    src={`/states/${
                                      option
                                        ? option.toLowerCase().substr(-3, 2)
                                        : ""
                                    }.png`}
                                    alt=""
                                  />
                                  {option}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <div className="countyBox">
                                  <div className="countyBox__imgBox">
                                    <img
                                      src={`/states/${
                                        selectedCounty
                                          ? selectedCounty
                                              .toLowerCase()
                                              .substr(-3, 2)
                                          : ""
                                      }.png`}
                                      alt=""
                                    />
                                  </div>
                                  <TextField {...params} label="County" />
                                </div>
                              )}
                              value={selectedCounty}
                              onChange={(event, newValue) => {
                                setSelectedCounty(newValue);
                              }}
                              disabled={!isMapClicked}
                            />
                          </div>
                        </Tooltip>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={operators}
                          sx={{ width: 250, marginTop: 1 }}
                          renderInput={(params) => (
                            <TextField {...params} label="Operator Name" />
                          )}
                          value={selectedOperator}
                          onChange={(event, newValue) => {
                            setSelectedOperator(newValue);
                          }}
                        />
                      </>
                    )}
                    <div
                      style={{
                        marginTop: 10,
                      }}
                    >
                      {viewChart && (
                        <Button
                          style={{
                            color: "#F5AA57",
                            borderColor: "#F5AA57",
                            minWidth: 120,
                            width: "100%",
                          }}
                          variant="outlined"
                          onClick={() => setViewChart(false)}
                        >
                          View Map
                        </Button>
                      )}
                    </div>
                    {viewChart && (
                      <Button
                        onClick={() => setShowDialog(true)}
                        startIcon={
                          <GetAppIcon sx={{ color: "#3C3D6B", fontSize: 50 }} />
                        }
                        style={{
                          color: "#3C3D6B",
                          borderColor: "#3C3D6B",
                          width: "100%",
                          marginTop: 10,
                        }}
                        variant="outlined"
                      >
                        Download XLS
                      </Button>
                    )}
                  </div>
                )}
              </div>
              {userHasAccess &&
                (viewChart ? (
                  <div className="frackingCharts">
                    <div className="frackingCharts__box">
                      <div className="frackingChart">
                        <FrackingChart
                          chartRef={chartRef}
                          datesAndLables={datesAndLabels}
                          isLoading={isLoading}
                          chartType="Fracking"
                        />
                      </div>
                      <div className="frackingChart">
                        <FrackingChart
                          chartRef={rigRef}
                          datesAndLables={rigsDatesAndLabels}
                          isLoading={isLoading}
                          chartType="Rigs"
                        />
                      </div>
                    </div>

                    <div className="frackingCharts__box frackingCharts__box--2">
                      <div className="frackingChart">
                        <BarChart barData={barData} isLoading={isLoading} />
                      </div>
                      <div className="frackingChart">
                        <FrackingChart
                          chartRef={ducRef}
                          datesAndLables={ducsDatesAndLabels}
                          isLoading={isLoading}
                          chartType="DUCs"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <Map
                    resultMap={resultMap}
                    setViewChart={setViewChart}
                    token={token}
                  />
                ))}
            </>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress size={80} />{" "}
            </div>
          )}
        </div>
      )}
      {!userHasAccess && (
        <div className="landingPage">
          <div className="landingPage__content">
            <h1>
              Hyperion, every frac crew and drilling rig in near real time
            </h1>
            <Button
              onClick={(e) => {
                window.location = "mailto:info@synmax.com";
              }}
              variant="contained"
              id="contact"
              style={{ width: 280, marginRight: 20 }}
            >
              {isloggedIn
                ? "Contact Us For Full Access"
                : "Contact Us For More Details"}
            </Button>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default Hyperion;
