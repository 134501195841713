import { leapYearDates } from "../allDates";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

export const useStyles = makeStyles({
  option: {
    '&[aria-selected="true"]': {
      backgroundColor: "red",
    },
  },
});

const allMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const getMonthlyDataforXLS = (arr = [], idRequired) => {
  const newArr = [];
  allMonths.forEach((date, i) => {
    const count = arr[i];
    if (idRequired) {
      newArr.push({
        id: i + 1,
        date,
        count: count === "*" ? "No Data" : count,
      });
    } else {
      newArr.push({
        date,
        count: count === "*" ? "No Data" : count,
      });
    }
  });
  return newArr;
};

export const getXLSData = (arr = [], idRequired) => {
  const newArr = [];
  leapYearDates.forEach((date, i) => {
    const count = arr[i];
    if (idRequired) {
      newArr.push({
        id: i + 1,
        date,
        count: count === "*" ? "No Data" : count,
      });
    } else {
      newArr.push({
        date,
        count: count === "*" ? "No Data" : count,
      });
    }
  });
  return newArr;
};

export const columnSchema = [
  {
    field: "id",
    headerName: "row",
    width: 20,
    hide: true,
  },
  { field: "date", headerName: "Date", width: 120 },
  { field: "count", headerName: "Count", width: 100 },
];

const fillEmptyMonthsWithAsterisk = (dates, labels) => {
  const finalArr = [];

  allMonths.forEach((month) => {
    const indexOf = labels.indexOf(month);
    if (indexOf === -1) {
      finalArr.push("*");
    } else {
      finalArr.push(dates[indexOf]);
    }
  });
  return finalArr;
};

export const getFlagDataForSubRegion = (subregionName) => {
  const name = subregionName.toLowerCase();

  if (name === "colorado wo sj") {
    return "co";
  }
  if (name === "newmexico") {
    return "nm";
  }
  if (name === "wyoming") {
    return "wy";
  }
  if (name === "north dakota") {
    return "nd";
  }
  return name.substr(-2, 2);
};

function daysInMonth(month, year) {
  return new Date(year, month + 1, 0).getDate();
}

function loopThroughDatesAndFillData(startYear, endYear, dataWithPoints) {
  const datesWithPoints = {};

  for (let year = startYear; year <= endYear; year++) {
    const valuesForYear = [];

    for (let month = 0; month < 12; month++) {
      for (let day = 1; day <= daysInMonth(month, year); day++) {
        const dateKey = moment(new Date(year, month, day)).format("DD-MM-YYYY");
        if (dataWithPoints[dateKey] !== undefined) {
          valuesForYear.push(dataWithPoints[dateKey]);
        } else {
          valuesForYear.push("*");
        }
      }
    }
    datesWithPoints[year] = valuesForYear;
  }
  return datesWithPoints;
}

export function getSeasonalTimeseriesColor(year) {
  const lastDigit = parseInt(year.slice(-1));
  const colorMap = {
    0: "rgb(20,255,217)",
    1: "rgb(235,127,82)",
    2: "rgb(104,162,211)",
    3: "rgb(90,186,96)",
    4: "rgb(128,0,128)",
    5: "rgb(51,255,87)",
    6: "rgb(0,0,0)",
    7: "rgb(255,255,0)",
    8: "rgb(241,81,82)",
    9: "rgb(36,40,98)",
  };

  return colorMap[lastDigit] || "rgb(0,0,0)"; // Default to black if not found
}

export const getLineChartDataPointsSeasonal = (allData, isDucs) => {
  const convertedData = {};
  const startYear = allData?.[0].date.slice(0, 4);
  const endYear = allData?.[allData.length - 1].date.slice(0, 4);
  allData?.forEach((item) => {
    const date = moment(item.date).format("DD-MM-YYYY");
    convertedData[date] = isDucs ? item["count"] : item[""];
  });
  const pointsWithDateKey = loopThroughDatesAndFillData(
    startYear,
    endYear,
    convertedData
  );
  // Organize data by years
  return pointsWithDateKey;
};

export const getSeasonalChartPointsData = (points, year) => {
  const arrToReturn = points;
  const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };
  if (!isLeapYear(parseInt(year)) && arrToReturn[59] !== "*") {
    arrToReturn.splice(59, 0, "*");
  }
  return arrToReturn;
};

export const getBarDataSeasonal = (dates) => {
  const barData = {};
  const startYear = parseInt(dates?.[0].month.slice(0, 4));
  const endYear = parseInt(dates?.[dates.length - 1].month.slice(0, 4));

  // Iterate over the years dynamically
  for (let year = startYear; year <= endYear; year++) {
    const datesArray = [];
    const labelsArray = [];
    dates.forEach((date) => {
      const dateYear = parseInt(date.month.slice(0, 4));
      if (dateYear === year) {
        const newDate = moment(date.month.slice(0, 10)).format("MMM");
        datesArray.push(date[""]);
        labelsArray.push(newDate);
      }
    });
    const valuesArray = fillEmptyMonthsWithAsterisk(datesArray, labelsArray);
    barData[year] = valuesArray;
  }
  return barData;
};
