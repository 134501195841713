import React, { useState, useEffect } from 'react';
import L from 'leaflet';
import {
  Map,
  TileLayer,
  LayersControl,
  Marker,
  FeatureGroup,
  withLeaflet
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';
import { CircularProgress } from '@mui/material';

import EditControl from './DrawTool';
import MouseCoordinates from '../../components/MouseCoordinates';
import Pipeline from './Pipeline';
import MikePipelines from './MikePipelines';

const options = {
  type: 'protobuf',
  url: "http://35.184.93.48:8081/public.trrc_pipelines/{z}/{x}/{y}.pbf",
  interactive: true,
    minZoom: 10
};

const drillIcon = L.icon({
  iconUrl: './drilling.png',
  iconSize: [35, 35],
});

const frackIcon = L.icon({
  iconUrl: './fracking.png',
  iconSize: [35, 35],
});

const measureOptions = {
  position: 'topright',
  primaryLengthUnit: 'meters',
  secondaryLengthUnit: 'kilometers',
  primaryAreaUnit: 'sqmeters',
  secondaryAreaUnit: 'acres',
  activeColor: '#db4a29',
  completedColor: '#9b2d14'
};

const Leaflet = ({ resultMap, setViewChart, token }) => {
  const [isFrackVisible, setIsFrackVisible] = useState(true);
  const [isRigsVisible, setIsRigsVisible] = useState(true);
  const [isPipelinesVisible, setIsPipelinesVisible] = useState(true)
  const [zoomedOutData, setZoomedOutData] = useState(null)  
  const [zoomedInData, setZoomedInData] = useState(null)  
  const [zoomLevel, setZoomLevel] = useState(4)
  const [isLoading, setIsLoading] = useState(false)
 
  // useEffect(() => {
  //   getAllPipelines()
  // },[])
  const getAllPipelines = async () => {
    setIsLoading(true);
    const allPipelines = await axios.get('/geoml/allPipelines', {headers: { Authorization: 'Bearer ' + token }})
    const { zoomedOut } = allPipelines.data;
    setZoomedOutData({"type":"FeatureCollection", "features":zoomedOut })
    //const dbPipelines = await axios.get('/geoml/allPipelines', {headers: { Authorization: 'Bearer ' + token }})
    console.log(allPipelines, 'allPipelines')
    setIsLoading(false)
  }
  
  const getFireMarkers = () => {
    const markers = [];

    resultMap.forEach((item, i) => {
      let icon;

      if (item.status === 'Fracking') {
        icon = frackIcon;

        if(isFrackVisible) {
          markers.push(
            <Marker key={i} position={[item.lat, item.long]} icon={icon} />
          );
        }
      }
      if (item.status === 'Drilling') {
        icon = drillIcon;

        if(isRigsVisible) {
          markers.push(
            <Marker key={i} position={[item.lat, item.long]} icon={icon} />
          );
        }
      }
     
    });

    return markers;
  };

  const onZoom = (e) => {
    setZoomLevel(e.target._zoom)
    console.log(e.target._zoom, 'e.target._zoom')
  }

  const polygonCreated = async (e) => {
    let polygonString = ''
    e.layer._latlngs[0].forEach((point, i) => {
      polygonString += `${point.lng} ${point.lat},`
    })
    setIsLoading(true);
    const allPipelines = await axios.get('/geoml/polygonData', {
      headers: { Authorization: 'Bearer ' + token },
      params: {
        polygonString: polygonString.slice(0,-1),
      },
    });
    const { zoomedOut } = allPipelines.data;
    setZoomedInData({"type":"FeatureCollection", "features":zoomedOut })
  }

  const onMeasurefinish = (e) => {
    console.log(e, ':e')
  }

  // var options = {
  //   maxZoom: 16,
  //   tolerance: 3,
  //   debug: 0,
  //   style: {
  //     fillColor: "#1EB300",
  //     color: "#F2FF00",
  //     weight: 2
  //   }
  // };

  const optimizeAtZoomLevel = 10
  return (
    <div className="leafletMain">
      {isLoading && (
        <div className="leafletMain__loader">
          <CircularProgress size={80} />
        </div>
      )}
      <div className="leafletMain__top">
        <div className="leafletMain__icons">
          <div
            className="icon icon__1"
            onClick={() => setIsFrackVisible(!isFrackVisible)}
          >
            <div className="iconBox">
              <img src="./fracking.png" alt="fracking" />
            </div>
            <div className="iconText">
              {isFrackVisible ? 'Fracking' : <s>Fracking</s>}
            </div>
          </div>
          <div
            className="icon icon__2"
            onClick={() => setIsRigsVisible(!isRigsVisible)}
          >
            <div className="iconBox">
              <img src="./drilling.png" alt="Rigs" />
            </div>
            <div className="iconText">
              {isRigsVisible ? 'Rigs' : <s>Rigs</s>}
            </div>
          </div>
          <div
            className="icon"
            onClick={() => setIsPipelinesVisible(!isPipelinesVisible)}
          >
            <div className="iconBox">
              <img src="./pipelines.png" alt="Rigs" />
            </div>
            <div className="iconText">
              {isPipelinesVisible ? 'Pipelines' : <s>Pipelines</s>}
            </div>
          </div>
        </div>

        <Button
          style={{
            color: '#F5AA57',
            borderColor: '#F5AA57',
            minWidth: 120,
            // marginLeft: 10,
          }}
          variant="outlined"
          onClick={() => setViewChart(true)}
          startIcon={<ArrowBackIcon sx={{ color: '#F5AA57' }} />}
        >
          Back to Chart
        </Button>
      </div>
      <Map
        key={'reportmap'}
        preferCanvas={true}
        style={{ width: '100%', height: '100%' }}
        className="ukraine__mapComponent"
        center={{
          lat: 37.0902,
          lng: -95.7129,
        }}
        zoom={4}
        maxZoom={18}
        minZoom={2}
        worldCopyJump={true}
        onzoom={onZoom}
      >
        {getFireMarkers()}
        {/* {zoomedInData && <Pipeline pipelineData={zoomedInData} key={zoomLevel}/>}
        {zoomedOutData && !zoomedInData && <Pipeline pipelineData={zoomedOutData} key={zoomLevel} />} */}
        {/* <MeasureControl {...measureOptions} onMeasurefinish={onMeasurefinish}/> */}
      {isPipelinesVisible && <MikePipelines zoomLevel={zoomLevel}/>}  
        <FeatureGroup>
          <EditControl
            position="topright"
            onEdited={(e) => console.log('edited: ', e)}
            onCreated={polygonCreated}
            onDeleted={(e) => console.log('deleted: ', e)}
            draw={{
              polyline: false,
              marker: false,
              circle: false,
              circlemarker: false
            }}
          />
        </FeatureGroup>
        <div className="leaflet-top leaflet-left coordinates">
          <MouseCoordinates />
        </div>
        <LayersControl position="topright">
          <LayersControl.BaseLayer checked name="Map (light)">
            <TileLayer
              preferCanvas={true}
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Map (English)">
            <TileLayer
              preferCanvas={true}
              url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
            />
          </LayersControl.BaseLayer>

          <LayersControl.BaseLayer name="Satellite">
            <TileLayer
              //  noWrap
              url="https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v9/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1Ijoia3Blc3NldHRvIiwiYSI6ImNsODM1aGl5MDAybWQzdW81azkwZWt4c2IifQ.hm20TDIv8E8Vt9PJv1tEyg"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
          </LayersControl.BaseLayer>
        </LayersControl>
      </Map>
    </div>
  );
};

export default Leaflet;
